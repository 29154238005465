<template>
  <div class="index">
    <iframe
      id="iframe"
      :src="url"
      width="100%"
      height="100%"
      scrolling="auto"
      frameborder="0"
    />
  </div>
</template>
<script>
export default {
  name: 'h5',
  data() {
    return {
      url: ''
    }
  },
  created() {
    this.url = this.$route.query.url
  }
}
</script>
<style scoped>
.index {
  width: 100%;
  height: 100vh;
}
</style>
